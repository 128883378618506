<template>
	<main class="page-container" id="subscription-center">
		<div class="page-header">
			<div class="page-component">
				<h2 class="page-header-title">정기구독관</h2>
				<p class="page-header-desc">내가 원할때 받을 수 있는 <br />정기구독 상품을 찾아보세요.</p>
			</div>
		</div>
		<div class="page-component">
			<section class="summary-wrap">
				<div class="fl"></div>
				<div class="fr">
					<h3 class="summary-title">정기구독 지원사업이란?</h3>
					<div class="summary">
						<div class="summary-header">
							<h3 class="summary-header-title"><strong>01</strong>지원내용</h3>
						</div>
						<div class="summary-content">
							<p class="summary-content-text">
								<span class="summary-content-text-num">1.</span>
								<span class="summary-content-text-numx">소상공인의 안정적인 판로 확보를 위해 정기배송이 가능한 정기구독관에 입점을 지원합니다.</span>
							</p>
							<p class="summary-content-text">
								<span class="summary-content-text-num">2.</span>
								<span class="summary-content-text-numx">소상공인 상품의 판매 촉진을 위해 광고, 마케팅 및 할인쿠폰 지원 등 다양한 판로 지원방안을 실시하고 있습니다.</span>
							</p>
						</div>
					</div>
					<div class="summary">
						<div class="summary-header">
							<h3 class="summary-header-title"><strong>02</strong>지원자격</h3>
						</div>
						<div class="summary-content">
							<p class="summary-content-text">정기구독 플랫폼 입점 및 활용이 가능한 소상공인 <em>( 소상공인 확인서, 국세 지방세 납세 증명서 제출 필요)</em></p>
						</div>
					</div>
					<div class="summary">
						<div class="summary-header">
							<h3 class="summary-header-title"><strong>03</strong>신청방법</h3>
						</div>
						<div class="summary-content center">
							<ol class="summary-content-text center">
								<li class="summary-content-text-depth">통합기업회원 가입</li>
								<li class="summary-content-text-depth">지원사업 공고 확인</li>
								<li class="summary-content-text-depth">정기구독 지원사업 신청</li>
							</ol>
						</div>
					</div>
				</div>
			</section>

			<!-- 모바일 헤더 -->
			<div class="section-subscription-mtab">
				<button type="button" class="button-tab" :class="{ 'is-active': tabVisible === 0 }" @click.prevent="tabClick(0)">민간쇼핑몰</button>
				<button type="button" class="button-tab" :class="{ 'is-active': tabVisible === 1 }" @click.prevent="tabClick(1)">지자체 쇼핑몰</button>
				<button type="button" class="button-tab" :class="{ 'is-active': tabVisible === 2 }" @click.prevent="tabClick(2)">소상공인 쇼핑몰</button>
			</div>

			<section class="section-subscription" :class="{ subs1: idx1 === 0, subs2: idx1 === 1, subs3: idx1 === 2, 'is-active': tabVisible === idx1 }" v-for="(centerBody, key, idx1) in subsPlate" :key="'dcd_' + key">
				<div v-if="centerBody.centerList.length > 0">
					<div class="section-header">
						<h3 class="section-header-title">{{ centerBody.centerNm }}</h3>
					</div>
					<div class="section-content">
						<swiper class="swiper" :ref="`swipeSubs${idx1 + 1}`" :options="swiperOptionTemplateList[idx1]">
							<swiper-slide v-for="(center, idx2) in centerBody.centerList" :key="idx2">
								<button type="button" class="section-subs-link" :class="{ 'is-active': sectionVisArray[idx1] === idx2 }" rel="noopener" @click="onClickSentPrMall(center, idx1, idx2)">
									<img :src="center.prMallImgUrl" alt="" @error="handleImgError" />
									<p class="section-subs-desc">{{ center.setnPrMallNm }}</p>
								</button>
							</swiper-slide>
						</swiper>
						<div class="swiper-buttons-container">
							<div class="swiper-button-prev" slot="button-prev"></div>
							<div class="swiper-button-next" slot="button-next"></div>
						</div>
						<!-- 추가 콘텐츠 -->
						<transition name="slide">
							<div>
								<div class="section-content-plus" :class="{ 'is-rounded': !activeMall[idx1].sentPrMallDtlDomainList.length }">
									<div class="section-content-plus__header">
										<strong>{{ activeMall[idx1].sentPrMallNm }}</strong>
										<a href="#" class="header" @click="onClickMallMove(activeMall[idx1])">
											<span>사이트로 이동</span>
											<i class="icon"></i>
										</a>
									</div>
									<div class="section-content-plus__body">
										<div class="section-content-plus__item is-bgimage">
											<span class="item-image">
												<img src="@/assets/img/common/bg_subscription.jpg" alt="" />
											</span>
											<span class="item-text">
												<strong class="item-text-title">다음 성공사례의 주인공을 찾습니다.</strong>
												<ul class="item-text-desc">
													<li>무료지원</li>
													<li>밀키트</li>
													<li>컨설팅</li>
												</ul>
												<a href="#" class="button-default is-small is-secondary is-rounded">지원사업 소개</a>
											</span>
										</div>
										<a href="#" class="section-content-plus__item" v-for="(dtl, i) in activeMall[idx1].sentPrMallDtlDomainList" @click="onClickDtlMove(dtl.sentPrMallDtlUrl)">
											<span class="item-image">
												<img :src="dtl.sentPrMallDtlImgUrl" alt="" />
											</span>
											<span class="item-text">
												<em>{{ dtl.sentPrMallDtlTitSubTyp }}</em>
												<strong>{{ dtl.sentPrMallDtlTit }}</strong>
												<span>{{ dtl.sentPrMallDtlCn }}</span>
											</span>
										</a>
									</div>
								</div>
							</div>
						</transition>

						<i class="section-content-bg__l"></i>
						<i class="section-content-bg__r"></i>
					</div>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import { ACT_GET_SUBS_ECNMY_LIST, ACT_UPDATE_SUBS_ECNMY_INQ, ACT_GET_COMMON_CODE_LIST, ACT_GET_SUBS_ECNMY_DTL_LIST } from '@/store/_act_consts';
import {} from '@/store/_mut_consts';

import { getItems, lengthCheck } from '@/assets/js/utils';
import errorImg from '@/assets/img/common/error.png';

export default {
	name: 'SubscriptionCenter',
	components: {},
	watch: {},
	computed: {},
	data: () => ({
		tabVisible: 0, // 섹션별 활성화 여부
		sectionVisArray: [-1, -1, -1], // 섹션별 몰 별 활성화
		activeMall: [], // 섹션별 몰 별 활성화의 item
		subsPlate: {},
		activeMallDtlList: [],
		dcdOptions: [],
		swiperOptionTemplateList: [],
		breakParam: {
			300: {
				spaceBetween: 10,
				slidesPerView: 2.3,
			},
			360: {
				spaceBetween: 10,
				slidesPerView: 2.5,
			},
			400: {
				spaceBetween: 10,
				slidesPerView: 3.2,
			},
			500: {
				spaceBetween: 10,
				slidesPerView: 3.7,
			},
			600: {
				spaceBetween: 10,
				slidesPerView: 4.6,
			},
			700: {
				spaceBetween: 10,
				slidesPerView: 5.2,
			},
			800: {
				spaceBetween: 15,
				slidesPerView: 3.2,
			},
			850: {
				spaceBetween: 15,
				slidesPerView: 3,
			},
			900: {
				spaceBetween: 15,
				slidesPerView: 3.5,
			},
			1000: {
				spaceBetween: 15,
				slidesPerView: 4,
			},
			1100: {
				spaceBetween: 15,
				slidesPerView: 4.5,
			},
			1280: {
				spaceBetween: 15,
				slidesPerView: 5.2,
			},
		},
	}),
	created() {
		this.getDcdOptions();
	},
	mounted() {},
	methods: {
		// promise ========================================================
		getDcdOptions() {
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: 1024 })
				.then((res) => {
					if (lengthCheck(res)) {
						this.dcdOptions = getItems(res).filter((dcd) => dcd.masterYn === 'N') || [];
						this.dcdOptions.forEach((dcd, idx) => {
							this.subsPlate[dcd.dcd] = {
								centerNm: dcd.dcdVal,
								centerList: [],
							};

							const _no = idx + 1;
							this.swiperOptionTemplateList.push({
								loop: false,
								slidesPerView: 5.5,
								spaceBetween: 15,
								navigation: {
									nextEl: `.subs${_no} .swiper-button-next`,
									prevEl: `.subs${_no} .swiper-button-prev`,
								},
								breakpoints: this.breakParam,
							});
						});

						this.getSubsEcnmyList();
					}
				})
				.catch(console.log);
		},
		getSubsEcnmyList() {
			this.$store
				.dispatch(`subsecnmy/${ACT_GET_SUBS_ECNMY_LIST}`, { opnYn: 'Y' })
				.then((res) => {
					if (200 == res.status.code) {
						let newPlate = { ...this.subsPlate };
						Object.keys(this.subsPlate).forEach((centerDcd, i) => {
							console.log(i);
							res.items.forEach((item, x) => {
								if (centerDcd == item.mnshpMallTypCd) {
									newPlate[centerDcd].centerList.push(item);
								}
							});
						});
						Object.keys(newPlate).forEach((centerDcd, i) => {
							if (newPlate[centerDcd].centerList.length) {
								newPlate[centerDcd].centerList.forEach((item, x) => {
									// 사례가 있는 몰을 디폴트로 선택되게
									if (item.sentPrMallDtlDomainList.length) {
										if (this.sectionVisArray[i] === -1) {
											this.sectionVisArray[i] = x;
											this.activeMall[i] = item;
										}
									}
								});
							} else {
								this.sectionVisArray[i] = 0;
							}
						});
						this.subsPlate = newPlate;
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
		// 해당 센터로 페이지 이동시 조회수 증가
		updateSubsEcnmyInq(_sentPrMallId) {
			const _param = { sentPrMallId: _sentPrMallId };
			this.$store
				.dispatch(`subsecnmy/${ACT_UPDATE_SUBS_ECNMY_INQ}`, _param)
				.then((res) => {})
				.catch(console.error);
		},

		// custom fn ========================================================
		onClickSentPrMall(mall, id, key) {
			this.tabVisible = id;
			this.sectionVisArray.splice(id, 1, key);
			this.activeMall[id] = mall;
		},
		onClickMallMove(mall) {
			this.updateSubsEcnmyInq(mall.sentPrMallId);
			window.open(mall.prMallUrl);
		},
		onClickDtlMove(url) {
			window.open(url);
		},

		// custom fn ========================================================
		handleImgError(e) {
			e.target.src = errorImg;
		},

		// 탭 클릭에 따른 활성화
		tabClick(el) {
			let centerOneObj;
			Object.keys(this.subsPlate).forEach((key, idx) => {
				if (idx === el) {
					centerOneObj = this.subsPlate[key].centerList[0];
				}
			});
			if (centerOneObj) {
				this.onClickSentPrMall(centerOneObj, el, 0);
			}
		},
	},
};
</script>
<style>
@media screen and (min-width: 801px) {
	.slide-enter-active {
		-moz-transition-duration: 0.3s;
		-webkit-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-moz-transition-timing-function: ease-in;
		-webkit-transition-timing-function: ease-in;
		-o-transition-timing-function: ease-in;
		transition-timing-function: ease-in;
	}

	.slide-leave-active {
		-moz-transition-duration: 0.3s;
		-webkit-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	}

	.slide-enter-to,
	.slide-leave {
		max-height: 231px;
		overflow: hidden;
	}

	.slide-enter,
	.slide-leave-to {
		overflow: hidden;
		max-height: 0;
	}
}
</style>
